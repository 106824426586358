import React from 'react';
import { AuthProvider } from 'react-check-auth';
import { withRouter, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './routes/privateRoute';
import MuseHeader from './layout/MuseHeader';
import MuseFooter from './layout/MuseFooter';
import { API_CONFIG } from './utils/config';
import Home from './pages/Home';
import Available from './pages/Available';
import Install from './pages/Install';
import NotFound from './pages/NotFound';
import DocumentationDetails from './pages/DocumentationDetails';
import DocumentationIndex from './pages/DocumentationIndex';
import TaxonomyContainer from './pages/TaxonomyContainer';

import 'fe-fabric/dist/assets/css/fabric-dark.css';
import './styles/App.scss';

const isDocsUrl = ({ pathname }) => {
  return !(pathname.match(/docs/) && pathname.match(/docs/).length > 0);
};

const App = ({ location }) => {
  return (
    <>
      <div className="mainContainer">
        <AuthProvider authUrl={API_CONFIG.api.userInfo}>
          {isDocsUrl(location) ? <MuseHeader /> : ''}
          <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/available" component={Available} />
            <PrivateRoute exact path="/taxonomy" component={TaxonomyContainer} />
            <PrivateRoute exact path="/install/:integration" component={Install} />
            <PrivateRoute exact path="/docs/:id" component={DocumentationDetails} />
            <PrivateRoute exact path="/docs" component={DocumentationIndex} />
            {/* Catch any 404 route and redirect back to the homepage */}
            <Route component={NotFound} />
          </Switch>
        </AuthProvider>
      </div>
      {isDocsUrl(location) ? <MuseFooter /> : ''}
    </>
  );
};

export default withRouter(App);
