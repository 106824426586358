import React, { useRef, useCallback, useEffect } from 'react';

export default function Pagination({ totalRecords, pageSize, gotoPage, setPageSize, pageIndex }) {
  const paginationEl = useRef(null);

  const gotoPageCallback = useCallback(
    e => {
      const nextPage = e.detail.page - 1;
      gotoPage(nextPage);
    },
    [gotoPage]
  );

  const perPagePageCallback = useCallback(
    e => {
      setPageSize(e.detail.perPageActive);
    },
    [setPageSize]
  );

  useEffect(() => {
    const page = parseInt(pageIndex,10) + 1;
    paginationEl.current.activePage = page;
  }, [gotoPage, pageIndex]);

  useEffect(() => {
    const el = paginationEl.current;
    if (!paginationEl) return;
    paginationEl.current.perPageOpt = [10, 25, 50, 100];
    paginationEl.current.addEventListener('pageChange', gotoPageCallback);
    paginationEl.current.addEventListener('perPageClick', perPagePageCallback);

    return () => {
      if (el) {
        el.removeEventListener('pageChange', gotoPageCallback);
        el.removeEventListener('perPageClick', perPagePageCallback);
      }
    };
  }, [paginationEl, gotoPageCallback, perPagePageCallback]);

  return (
    <div className="pagination">
      <muse-numeric-pagination
        per-page-active={pageSize}
        total-records={totalRecords}
        first-last-btns="true"
        ref={paginationEl}
      >
        <span slot="per-page-label">Rows per Page</span>
      </muse-numeric-pagination>
    </div>
  );
}
