import React from 'react';
import { AuthConsumer } from 'react-check-auth';
import { Helmet } from 'react-helmet';
import { setupGainsight } from '../utils/metrics';
import { APP_CONFIG } from '../utils/config';
import { logout } from '../utils/services';

const MuseHeader = () => {
  return (
    <AuthConsumer>
      {({ userInfo }) =>
        userInfo ? (
          <muse-top-nav product-title="Cloud Connect" product="market">
            <Helmet>
              <script type="text/javascript">{` ${setupGainsight(userInfo, APP_CONFIG.gainsight)} `}</script>
            </Helmet>

            <muse-top-nav-dropdown slot="nav-avatar" is-util="true">
              <muse-avatar
                slot="top-nav-dropdown-trigger"
                user-name={`${userInfo.user_info.profile.nickname}`}
                size="small"
              ></muse-avatar>
              <muse-top-nav-link
                onClick={e => {
                  // window.location.href = '/logout';
                  logout().catch(error => console.log(error));
                }}
              >
                Logout
              </muse-top-nav-link>
            </muse-top-nav-dropdown>
          </muse-top-nav>
        ) : null
      }
    </AuthConsumer>
  );
};

export default MuseHeader;
