import axios from 'axios';
import { API_CONFIG } from '../utils/config';

axios.interceptors.response.use(function (response) {
  if(response.data && response.data.redirect_url && response.data.redirect_url !== ''){
    window.location = response.data.redirect_url
  } else {
    return response;
  }
}, function (error) {
  return Promise.reject(error);
});


export function loadTableRows(params){
  return axios.get(API_CONFIG.api.details, {params});
}

export function saveTableRows(payload){
  return axios.post(API_CONFIG.api.edit_integration, payload);
}

export function deleteIntegration(integration_id){
  return axios.delete(API_CONFIG.api.integrations, {
    data: {
      integration_id,
    },
  });
}

export function disableIntegrationApi(integration_id){
  return axios.post(API_CONFIG.api.disable_integrations, {
    integration_id
  });
}

export function enableIntegrationApi(integration_id){
  return axios.post(API_CONFIG.api.enable_integrations, {
    integration_id
  });
}

export function logout(){
  return axios.post(`${API_CONFIG.api.base}logout`);
}

export function getIntegrations(){
  return axios.get(API_CONFIG.api.integrations);
}

export function getDocumentationDetails(id){
  return axios.get(`${API_CONFIG.api.steps}${id}`);
}

export function getIntegrationsConfig(){
  return axios.get(API_CONFIG.api.config);
}

export const axiosApi = axios;
