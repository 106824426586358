import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';
const { applyPolyfills, defineCustomElements } = require('@muse/components/loader');

Sentry.init({
  dsn: 'https://98aa7842c5e542738b8e15ee23e47127@sentry.io/2244195',
  release: process.env.REACT_APP_GIT_HASH,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
applyPolyfills().then(defineCustomElements);
