import React, { useState } from 'react';
import { FeToggle } from 'fe-fabric-react/lib/core';
export default function ToggleableToggle(props){
  const {row, field, editHandler} = props;
  const [value, setValue] =  useState(field.value);
  
  const clicked = ()=>{
    const currentValue = value;
    setValue(!currentValue);

    return editHandler(row, field.name, !currentValue)
    .catch(()=>{
      setValue(currentValue);
    });
  }
  return (
    <div style={{paddingTop: 10}}>
      {
        <FeToggle
          checked={value}
          onChange={clicked}
          size="small"   
        ></FeToggle>
      }
    </div>
  )
  
}