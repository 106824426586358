import React from 'react';

const styles = {
  wrapper: {
    height: '100%',
  },
  iframeContainer: {
    border: 0,
  },
};

const iframeUrl = process.env.NODE_ENV === 'production' ? '/taxonomy/index.html' : 'http://localhost:3000';

export default function TaxonomyContainer() {
  return (
    <div style={styles.wrapper}>
      <iframe
        style={styles.iframeContainer}
        title="Taxonomy Container"
        width="100%"
        height="100%"
        src={iframeUrl}
        sandbox="allow-same-origin allow-scripts"
      ></iframe>
    </div>
  );
}
