import React from 'react';

export default function Legend() {
  return (
    <div className="legend">
      <muse-tooltip>
        <div slot="tooltip-trigger">
          <div className="ok">
            <div className="swatch"></div>
          </div>
        </div>
        <div>Ok - Connection working correctly</div>
      </muse-tooltip>
      <muse-tooltip>
        <div slot="tooltip-trigger">
          <div className="disabled">
            <div className="swatch"></div>
          </div>
        </div>
        <div>Disabled - Connection paused</div>
      </muse-tooltip>
      <muse-tooltip>
        <div slot="tooltip-trigger">
          <div className="review">
            <div className="swatch"></div>
          </div>
        </div>
        <div>Review - Last event seen more than 24 hours ago</div>
      </muse-tooltip>
      <muse-tooltip>
        <div slot="tooltip-trigger">
          <div className="warning">
            <div className="swatch"></div>
          </div>
        </div>
        <div>Warning - Connection not getting events</div>
      </muse-tooltip>
      <muse-tooltip>
        <div slot="tooltip-trigger">
          <div className="critical">
            <div className="swatch"></div>
          </div>
        </div>
        <div>Critical - Connection not running</div>
      </muse-tooltip>
    </div>
  );
}
