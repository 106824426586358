import React, { useState, useEffect } from 'react';
import AvailableIntegration from '../components/availableIntegration';
import { API_CONFIG } from '../utils/config';

import { Link } from 'react-router-dom';

import groupBy from 'lodash/groupBy';
import snakeCase from 'lodash/snakeCase';

import '../styles/containers/Available.scss';
import { getIntegrations } from '../utils/services';

const CACHE = {};

export default function Available() {
  const [availableIntegrations, setAvailableIntegrations] = useState([]);
  const [integrationsByCategory, setIntegrationsByCategory] = useState({});
  const [total, setTotal] = useState(0);
  const [categories, setCategories] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const updateCategories = integrationList => {
    const fullList = integrationList.filter(integration => integration.category !== '');
    let grouped = groupBy(fullList, x => x.category);
    setTotal(fullList.length);
    setCategories(Object.keys(grouped).sort());
    setIntegrationsByCategory(grouped);
  };

  const page = API_CONFIG.api.integrations;

  useEffect(() => {
    if (CACHE[page] !== undefined) {
      updateCategories(CACHE[page]);
      setAvailableIntegrations(CACHE[page]);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    getIntegrations()
      .then(response => {
        CACHE[page] = response.data;
        updateCategories(response.data);
        setAvailableIntegrations(response.data);
        setIsLoading(false);
      })
      .catch(err => {
        setHasError(true);
        setIsLoading(false);
      });
  }, [page]);

  const searchIntegrations = event => {
    const str = event.target.value.toLowerCase();
    if (str === '') {
      updateCategories(availableIntegrations);
      return;
    }
    const filtered = availableIntegrations.filter(
      integration => integration.name.toLowerCase().includes(str) || integration.company.toLowerCase().includes(str)
    );
    updateCategories(filtered);
    setSearchTerm(str);
  };

  const onCategoryLinkClick = category => {
    const id = snakeCase(category);
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  const errorMessage = () => {
    return <div className="col-md-12 no-integrations-found-msg">Error loading data</div>;
  };

  const loadingAnimation = () => {
    return (
      <div className="col-md-12 loaderDiv">
        <muse-spinner custom-class="component-spinner" size="lg">
          Loading
        </muse-spinner>
      </div>
    );
  };

  const categoryLinks = () => {
    if (categories.length > 0) {
      return (
        <div className="col-12">
          <span className="label">Jump to Category:</span>
          <ul className="column-list">
            {(categories || []).map((category, idx) => (
              <li key={idx}>
                <button onClick={e => onCategoryLinkClick(category)}>
                  {category} ({(integrationsByCategory[category] || []).length})
                </button>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  const categoryGroups = () => {
    return categories.map((category, idx) => (
      <div className="col-12" key={idx}>
        <div className="col-12">
          <h1 id={snakeCase(category)} className="header">
            {category}
          </h1>
        </div>
        <div className="row">
          {(integrationsByCategory[category] || []).map(item => (
            <AvailableIntegration key={item.id} item={item} iconpos />
          ))}
        </div>
      </div>
    ));
  };

  const emptySearchMessage = () => {
    return (
      <div className="col-md-12 empty-message">
        We can't find any integrations matching the search:
        <muse-label>
          <muse-icon name="chevron-right" size="sm"></muse-icon>
          {searchTerm}
        </muse-label>
      </div>
    );
  };
  return (
    <div className="Home">
      <div className="fe-hero bg-dark">
        <Link to="/">
          <muse-button button-type="hollow">
            <muse-icon name="chevron-left-circle"></muse-icon>
            Back to Installed Connections
          </muse-button>
        </Link>
        <div className="fe-hero__title">
          <h1 className="page-title fe-hero__title-text">
            Add Connection
            <span className="total">{total > 0 && `(${total} Available)`}</span>
          </h1>
        </div>
        <div className="fe-hero__body">
          <div className="margin-search">
            <muse-field icon="search">
              <label className="muse-input__label">
                &nbsp;
                <input
                  className="muse-input"
                  placeholder="Search connections"
                  onChange={e => {
                    searchIntegrations(e);
                  }}
                ></input>
              </label>
            </muse-field>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">{categoryLinks()}</div>
        <div className="row">
          <div className="col-9"></div>
          <div className="col-3"></div>
        </div>

        {isLoading && loadingAnimation()}

        {hasError ? (
          errorMessage()
        ) : (
          <div className="row">
            {searchTerm !== '' && categories.length < 1 && emptySearchMessage()}
            {categoryGroups()}
          </div>
        )}
      </div>
    </div>
  );
}
