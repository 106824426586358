import React, { useState, useEffect } from 'react';
import Logo from '../components/logo';
import { Link, useParams } from 'react-router-dom';
import { API_CONFIG } from '../utils/config';
import { getDocumentationDetails, getIntegrations } from '../utils/services';

// const integrationList = require('./docs.json');

const styles = {
  wrapper: {
    backgroundColor: '#fff',
    height: '100%',
    color: '#000',
    margin: 50,
  },
  page: {
    flexDirection: 'column',
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 35,
    margin: 20,
  },
  image: {
    display: 'inline-block',
    width: 50,
    height: 50,
    borderRadius: 5,
    marginRight: 10,
  },
  title: {
    fontSize: 24,
  },
  vendor: {
    fontSize: 12,
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  h2: {
    marginLeft: 12,
    fontSize: 14,
    fontWeight: 'bold'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
  },
  list: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
  },
  list_hint: {
    fontSize: 12,
    fontStyle: 'italic',
    textAlign: 'justify',
    paddingBottom: 10,
    margin: 12,
  },
  description: {
    textAlign: 'center',
  },
  details: {
    display: 'inline-block',
  },
  logo: {
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitle: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    color: '#000',
    fontSize: '30px',
    textTransform: 'uppercase',
    paddingLeft: '20px'
  },
  content: {
    fontSize:14,
    paddingTop: '20px'
  },
  ulList: {
    marginLeft: 50,
    marginBottom: 50,
    listStyleType: 'none'
  }
};

const Documentation = () => {
  let { id } = useParams();
  const [details, setDetails] = useState([]);
  const [integration, setIntegration] = useState([]);
  const detailsUrl = `${API_CONFIG.api.steps}${id}`;
  const listUrl = API_CONFIG.api.integrations;

  useEffect(() => {
    getIntegrations()
      .then(response => {
        const item = response.data.filter(item => item.integration === id);
        setIntegration(item[0]);
      })
      .catch(error => console.error(error));

    getDocumentationDetails(id)
      .then(response => {
        setDetails(response.data);
      })
      .catch(error => console.error(error));
  }, [id, listUrl, detailsUrl]);

  return (
    <div className="docs" style={styles.wrapper}>
      <div style={styles.page}>
        <div style={styles.header}>
          <div style={styles.logo}>
            <Link to="/docs/">
              <Logo />
            </Link>
          </div>
          <div style={styles.headerTitle}>Helix Connect</div>
        </div>
        <div style={styles.description}>
          <img style={styles.image} src={integration.logo} alt={integration.description} />
          <div style={styles.details}>
            <div style={styles.title}>{integration.name}</div>
            <div style={styles.vendor}>{integration.company}</div>
          </div>
        </div>
        <h4 style={styles.subtitle}>Installation</h4>
        {details &&
          details.map((step, i) => (
            <div key={'step' + i}>
              <div style={styles.section}>
                <div key={'step' + i}>
                  <span style={styles.h2}>{step.stepNumber} - {step.stepName}</span>
                  <ul style={styles.ulList}>
                    {step &&
                      step.stepItems.map(
                        (val, i) =>
                          val && (
                            <li key={'stepitem' + i} style={styles.content}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: val,
                                }}
                              ></div>
                            </li>
                          )
                      )}
                  </ul>
                  {step && step.inputs && (
                    <div>
                      <div style={styles.h2}>Inputs</div>
                      <ul style={styles.ulList}>
                        {step.inputs.map((val, i) => (
                          <li key={'inputs' + i}>
                            <span style={styles.list}>{val.label}</span>
                            <span style={styles.list_hint}>{val.hint}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Documentation;
