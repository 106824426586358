import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FeHero, FeButton, FeInputField, FeLoader, FeDropdown, FeCheckbox, FeTextarea } from 'fe-fabric-react';
import Steps, { Step } from 'rc-steps';

import qs from 'query-string';

import { trackPxInstall, trackSentryError } from '../utils/metrics';
import { API_CONFIG } from '../utils/config';
import { LANG_EN } from '../utils/lang';

import '../styles/containers/Install.scss';
import '../styles/rc-steps/index.scss';
import '../styles/rc-steps/iconfont.css';
import { FeToggle } from 'fe-fabric-react/lib/core';
import { axiosApi } from '../utils/services';

class InstallPage extends Component {
  constructor(props) {
    super(props);
    const selectedHelixInstance = this.getDefaultSelectedHelixInstance(props.user.helix_ids);
    this.state = {
      stepProgressBlocked: true,
      currentStep: 0,
      steps: [],
      inputFields: {},
      hasVerified: false,
      hasSubmitted: false,
      helixInstance: selectedHelixInstance,
      helixInstanceErrorMsg: true,
      instanceOptions: props.user.helix_ids,
      instancePickerIsOpen: false,
      verifyStatus: null,
      verifyMessage: null,
      missingSteps: false,
      displayPassword: false,
    };

    this.previousStep = this.previousStep.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.finish = this.finish.bind(this);
    this.exitPreview = this.exitPreview.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxPasswordChange = this.handleCheckboxPasswordChange.bind(this);
  }

  getDefaultSelectedHelixInstance(helix_ids = {}) {
    const keys = Object.keys(helix_ids);
    return keys.length === 1 ? helix_ids[keys[0]] : '';
  }

  componentDidMount() {
    // Get the integration steps via an API call
    this.getSteps();
  }

  validateInput(event, validation, name, hint) {
    this.validateSelectedInstance(this.state.helixInstance);
    // Verify the input as it changes against the validation string provided
    let validate = event.match(new RegExp(validation));
    // Once the validation passes
    if (validate !== null) {
      // Unblock the step progress (dynamically enables the next step button)
      this.setState({
        stepProgressBlocked: false,
      });

      // Return a success message to the FeInputField component
      return {
        messageType: 'success',
        message: name + ' ' + LANG_EN.install.regex_match,
      };
    }

    // If validation doesn't pass, block step progress (as the user may have removed
    // all text) then return an error to the FeInputField
    this.setState({
      stepProgressBlocked: true,
    });

    return {
      messageType: 'error',
      message: hint,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("Updated state for inputFields from ", prevState.inputFields, " to ", this.state.inputFields);
  }

  inPreviewMode() {
    // Parse the query string and check for the preview key
    return 'preview' in qs.parse(this.props.location.search);
  }

  exitPreview() {
    this.setState({
      currentStep: 0,
    });

    // push the non-preview url to the history
    this.props.history.push(this.props.location.pathname);
  }

  getSteps() {
    // Make the API call here to retieve the steps for this integration
    const url = `${API_CONFIG.api.steps}${this.props.match.params.integration}`;
    axiosApi.get(url)
      .then(response => {
        let steps = response.data;
        let stateInputCopy = this.state.inputFields;
        let missingSteps = false;

        if (!steps || steps.length === 0) {
          missingSteps = true;
        }

        for (let i = 0; i < steps.length; i++) {
          // Ensure the step has inputs
          if (steps[i].inputs !== undefined) {
            // Now loop these inputs
            for (let j = 0; j < steps[i].inputs.length; j++) {
              // Push an empty key: value pair to the input state
              if (steps[i].inputs[j].field === 'checkbox') {
                stateInputCopy[steps[i].inputs[j].id] = steps[i].inputs[j].default
                  ? Boolean(steps[i].inputs[j].default)
                  : false;
              } else {
                stateInputCopy[steps[i].inputs[j].id] = '';
              }
            }
          }
        }

        // Set the steps in the state and the expected input fields as empty strings
        this.setState({
          steps,
          inputFields: stateInputCopy,
          missingSteps,
        });
      })
      .catch(error => {
        this.setState({
          missingSteps: true,
        });
      });
  }

  // Go back a step
  previousStep() {
    this.setState({
      currentStep: this.state.currentStep - 1,
    });
  }

  // Go to the next step
  nextStep() {
    // Always allow progress if in preview mode
    let blockProgress = !this.inPreviewMode() ? true : false;
    this.setState(
      {
        stepProgressBlocked: blockProgress,
        currentStep: this.state.currentStep + 1,
      },
      () => {
        // After each step is passed, scroll the user back to the top of the page
        // for the next steps
        window.scrollTo(0, 0);
      }
    );
  }

  validateSelectedInstance(helixInstance = '') {
    this.setState({ helixInstanceErrorMsg: !helixInstance });
  }

  finish() {
    // Once finished, redirect the user to the homepage
    this.props.history.push('/');
  }

  setHelixInstance(instance) {
    this.setState({
      helixInstance: instance,
    });
  }

  rcSteps() {
    // Dynamically create the steps from the API call result
    let steps = this.state.steps;
    let stepList = steps.map(function (step) {
      return <Step key={step.stepNumber} title={step.stepName} />;
    });

    return (
      <div>
        <div className="container-fluid">
          <Steps labelPlacement="vertical" current={this.state.currentStep}>
            {stepList}
          </Steps>
        </div>
      </div>
    );
  }

  handleCheckboxChange(event) {
    const target = event.target;
    const value = target.name === 'passwordToggle' ? target.checked : target.value;

    this.setState({
      displayPassword: value,
    });
  }

  handleCheckboxPasswordChange(event) {
    const target = event.target;
    const value = target.name === 'passwordToggle' ? target.checked : target.value;

    this.setState({
      displayPassword: value,
    });
  }

  handleChange(event) {
    // If the value is null or blank, block step progress (validateInput doesn't catch this)
    if (event.target.value === null || event.target.value === '' || event.target.value === undefined) {
      this.setState({
        stepProgressBlocked: true,
      });
    }

    // Mutate the state property as we are using dynamic key values
    // to assign the event.target.value field
    var stateInputCopy = this.state.inputFields;

    if (event.target.type === 'checkbox') {
      stateInputCopy[event.target.id] = event.target.checked;
    } else {
      stateInputCopy[event.target.id] = event.target.value;
    }
    // Now set the state to the new value
    this.setState({
      inputFields: stateInputCopy,
    });
  }

  renderInputOption(input) {
    if (input.field === 'toggle'){
      return (
        <div className="input-wrapper install-toggle" key={input.id}>
            <FeToggle
              value={input.default}
              id={input.id}
              name={input.id}
              onChange={this.handleChange}
              checked={this.state.inputFields[input.id]}
            /> 
            <label class="fe-checkbox__text">{input.label}</label>
        </div>
      )
    }
    else if (input.field === 'checkbox') {
      return (
        <div className="input-wrapper" key={input.id}>
          <FeCheckbox
            value="0"
            id={input.id}
            name={input.id}
            label={input.label}
            hint={input.hint}
            onChange={this.handleChange}
            checked={this.state.inputFields[input.id]}
          />
        </div>
      );
    } else if (input.field === 'textarea') {
      return (
        <div className="input-wrapper" key={input.id}>
          <FeTextarea
            rows="9"
            disabled={false}
            id={input.id}
            message={input.hint}
            value={this.state.inputFields[input.id]}
            label={input.label}
            onChange={this.handleChange}
          />
        </div>
      );
    } else {
      return (
        <div className="input-wrapper" key={input.id}>
          <FeInputField
            key={input.id}
            id={input.id}
            label={input.label}
            hint={input.hint}
            onChange={this.handleChange}
            validate={event => this.validateInput(event, input.validation, input.label, input.hint)}
            value={this.state.inputFields[input.id]}
            type={input.hidden && !this.state.displayPassword ? 'password' : 'text'}
          />
          {input.hidden && (
            <FeCheckbox
              value="demo"
              name="passwordToggle"
              label="Display hidden value"
              checked={this.state.displayPassword}
              onChange={this.handleCheckboxPasswordChange}
            />
          )}
        </div>
      );
    }
  }

  currentStepHtml() {
    // Assign the step items to a varible for easier looping
    let steps = this.state.steps[this.state.currentStep].stepItems;
    let inputList = [];
    let verifyStep = null;

    // Set up the return object for the dangerouslySetInnerHTML prop
    let innerHtml = {
      __html: '',
    };

    // Loop through the steps and append the items to the return object
    for (let i = 0; i < steps.length; i++) {
      innerHtml.__html += `<p>${steps[i]}</p>`;
    }

    // Check if the install is in preview mode (disable inputs and validation)
    // if it is in preview mode
    let inPreview = this.inPreviewMode();
    if (!inPreview) {
      let inputs = this.state.steps[this.state.currentStep].inputs;
      if (inputs) {
        inputList = inputs.map(input => this.renderInputOption(input));
      }

      // Now we're going to submit and verify verify the integrations
      let isVerificationStep = this.state.steps[this.state.currentStep].url !== undefined;
      if (isVerificationStep) {
        verifyStep = this.submitAndVerify(this.state.steps[this.state.currentStep].url);
      }
    }

    return (
      <React.Fragment>
        <div dangerouslySetInnerHTML={innerHtml} />
        {verifyStep}
        {!inPreview && inputList.length > 0 ? (
          <div className="InputContainer">
            <div className="row">
              <div className="col-md-6" style={{ margin: '0 auto' }}>
                <h3>
                  <i className="fal fa-fw fa-info-circle" />
                  {LANG_EN.install.input_required}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 InputStore" style={{ margin: '0 auto' }}>
                {inputList}
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  getHelixInstances() {
    let instanceList = [];
    for (let key in this.state.instanceOptions) {
      instanceList.push({
        id: this.state.instanceOptions[key],
        value: this.state.instanceOptions[key],
        label: `${key} (${this.state.instanceOptions[key]})`,
      });
    }

    return instanceList;
  }

  updateHelixInstance(event) {
    const helixInstance = event.originalEvent.currentTarget.value;
    this.setState({
      helixInstance: helixInstance,
    });
    this.validateSelectedInstance(helixInstance);
  }

  trackError(error) {
    trackSentryError(error, 'Integration Submit Error');
  }

  submitAndVerify(url) {
    if (!this.state.hasSubmitted) {
      // Submit the data to the URL provided
      let postData = {
        integration: this.props.match.params.integration,
        helix_instance: this.state.helixInstance,
        config: this.state.inputFields,
      };

      // Set up the return object for the dangerouslySetInnerHTML prop
      let innerHtml = {
        __html: '',
      };

      // Here is where we would POST the data to the endpoint provided, inside this call
      // we issue a GET request on an interval loop to verify ingestion, the loop will be
      // cancelled once the API returns a true result

      // Submit the data
      axiosApi
        .post(url, postData)
        .then(response => {
          if (response.data.verified) {
            this.setState({
              hasVerified: true,
              hasSubmitted: true,
              verifyStatus: response.data.verified,
            });
            trackPxInstall(true, this.props.match.params.integration, this.state.helixInstance);
          } else if (response.data.html) {
            innerHtml.__html = response.data.html;
            this.setState({
              hasVerified: true,
              hasSubmitted: true,
              verifyMessage: innerHtml,
            });
            trackPxInstall(true, this.props.match.params.integration, this.state.helixInstance);
          } else {
            this.setState({
              hasVerified: false,
              hasSubmitted: true,
              verifyStatus: response.status,
              verifyMessage: response.data.error,
            });
            this.trackError(response.data.error);
            trackPxInstall(false, this.props.match.params.integration, this.state.helixInstance);
          }
        })
        .catch(error => {
          this.setState({
            hasVerified: false,
            hasSubmitted: true,
            verifyStatus: error.response.status,
            verifyMessage: error.response.data.error,
          });
          this.trackError(error);
          trackPxInstall(false, this.props.match.params.integration, this.state.helixInstance);
        });
    }

    return (
      <div className="VerificationStep">
        {this.state.hasVerified ? (
          <React.Fragment>
            {this.state.verifyMessage ? (
              <div dangerouslySetInnerHTML={this.state.verifyMessage} />
            ) : (
              <div>
                <div className="vs-icon-wrapper">
                  <i className="fa fa-check-circle vs-icon" />
                </div>
                <span className="caption">{LANG_EN.install.successful_integration}</span>
                <div className="success-text">
                  {LANG_EN.install.success_message} <Link to={`/`}>{LANG_EN.install.success_message_link}</Link>.
                </div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <div>
            {this.state.verifyStatus !== true && this.state.hasSubmitted ? (
              <div>
                <div className="vs-icon-wrapper">
                  <i className="fa fa-times-circle vs-icon-danger" />
                </div>
                <div className="success-text">
                  <div>{LANG_EN.install.failed_integration}</div>
                  <div>{LANG_EN.install.failure_message}</div>
                  <pre>{this.state.verifyMessage}</pre>
                </div>
                <span className="caption"></span>
              </div>
            ) : (
              <FeLoader feSize="xxl" label={LANG_EN.install.verifying_integration} />
            )}
          </div>
        )}
      </div>
    );
  }

  generateDropdownLabel(helixInstance = '') {
    return helixInstance
      ? `${LANG_EN.install.helix_instance}: ${this.state.helixInstance}`
      : LANG_EN.install.select_instance;
  }

  areStepInputsOptional() {
    const currentInputs = this.state.steps[this.state.currentStep].inputs;
    let results;
    if (Array.isArray(currentInputs) && currentInputs.length > 0) {
      results = currentInputs.map(input => {
        if (!input.validation) {
          return true;
        }
        const validate = ''.match(new RegExp(input.validation));
        return validate !== null;
      });
      return results.every(x => x === true);
    } else {
      return false;
    }
  }

  getDisabledStatus() {
    const hasInputs = this.state.steps[this.state.currentStep].inputs !== undefined;
    const areInputsOptional = this.areStepInputsOptional();

    if (!this.inPreviewMode() && hasInputs && areInputsOptional && !this.state.helixInstanceErrorMsg) {
      return false;
    }

    return !this.inPreviewMode() && hasInputs
      ? this.state.stepProgressBlocked || this.state.helixInstanceErrorMsg
      : false;
  }

  render() {
    return (
      <div className="Install">
        {this.state.missingSteps && <h2 className="steps-missing">{LANG_EN.install.no_steps_found}</h2>}
        {this.state.steps.length > 0 && (
          <React.Fragment>
            <div className="InstallSticky">
              <FeHero title="Your Integration Instructions" descriptionContent={this.rcSteps()} className="bg-dark" />
              <div className="StepHero">
                <h2>
                  {LANG_EN.install.step} {this.state.currentStep + 1}:{' '}
                  {this.state.steps[this.state.currentStep].stepName}
                </h2>
                <div className="StepButtons">
                  {!this.inPreviewMode() ? (
                    <div className="instance-picker-wrapper">
                      <FeDropdown
                        className="instance-picker"
                        label={this.generateDropdownLabel(this.state.helixInstance)}
                        buttonStyle="secondary"
                        feType="menu"
                        size="md"
                        position="bottom-right"
                        withSearch={true}
                        activeId={this.state.helixInstance}
                        onItemClick={e => {
                          this.updateHelixInstance(e);
                        }}
                        onEscapeClose
                        list={this.getHelixInstances()}
                      />
                      {this.state.helixInstanceErrorMsg ? (
                        <div className="instance-picker-error-msg">{LANG_EN.install.select_one_instance}</div>
                      ) : null}
                    </div>
                  ) : null}
                  <FeButton
                    onClick={this.previousStep}
                    feStyle="secondary"
                    className="step-progress top-button"
                    disabled={
                      this.state.currentStep + 1 === 1 || this.state.currentStep + 1 === this.state.steps.length
                    }
                  >
                    {LANG_EN.install.previous_step}
                  </FeButton>
                  {this.state.currentStep + 1 === this.state.steps.length ? (
                    <FeButton onClick={this.finish} feStyle="primary" className="step-progress top-button">
                      {LANG_EN.install.back_to_home}
                    </FeButton>
                  ) : (
                    <FeButton
                      onClick={this.nextStep}
                      feStyle="primary"
                      className="top-button"
                      disabled={this.getDisabledStatus()}
                    >
                      {this.state.currentStep + 2 === this.state.steps.length
                        ? LANG_EN.install.submit_and_verify
                        : LANG_EN.install.next_step}
                    </FeButton>
                  )}
                  {this.inPreviewMode() ? (
                    <FeButton
                      onClick={this.exitPreview}
                      icon="fal fa-times-circle"
                      iconPosition="left"
                      className="exit-preview top-button"
                      feStyle="primary-danger"
                    >
                      {LANG_EN.install.exit_preview}
                    </FeButton>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="StepContainer">{this.currentStepHtml()}</div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(InstallPage);
