import React, { Component } from 'react';
import { FeModal } from 'fe-fabric-react';

import '../styles/components/EnabledIntegration.scss';
import { LANG_EN } from '../utils/lang';

import * as dayjs from 'dayjs';
import BasicTable from './basicTable';
import { deleteIntegration, disableIntegrationApi, enableIntegrationApi } from '../utils/services';

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

class EnabledIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalProps: null,
      integrationToHandle: null,
    };
  }

  toggleModal(modalProps) {
    this.setState({
      modalProps: modalProps,
    });
  }

  enableIntegrationModalContent() {
    return (
      <div>
        {LANG_EN.installed.enable_integration_msg.map((text, idx) => (
          <p key={idx}>{text}</p>
        ))}
      </div>
    );
  }

  disableIntegrationModalContent() {
    return (
      <div>
        {LANG_EN.installed.disable_integration_msg.map((text, idx) => (
          <p key={idx}>{text}</p>
        ))}
      </div>
    );
  }

  removeIntegrationModalContent() {
    return (
      <div>
        {LANG_EN.installed.remove_integration_msg.map((text, idx) => (
          <p key={idx}>{text}</p>
        ))}
      </div>
    );
  }

  showHideModalContent(item) {
    return (
      <div className="show-hide-modal-content">
        <h5>{item.name}</h5>
        <pre>{item.value}</pre>
      </div>
    );
  }

  generateModalProps(action = 'remove') {
    let title = '';
    let label = '';
    let dangerAction = () => {};
    let children = '';

    if (action === 'enable') {
      title = LANG_EN.installed.enable_integration;
      label = LANG_EN.installed.enable_integration;
      dangerAction = () => {
        this.enableIntegration();
      };
      children = this.enableIntegrationModalContent();
    } else if (action === 'remove') {
      title = LANG_EN.installed.remove_integration;
      label = LANG_EN.installed.remove_integration;
      dangerAction = () => {
        this.removeIntegration();
      };
      children = this.removeIntegrationModalContent();
    } else if (action === 'disable') {
      title = LANG_EN.installed.disable_integration;
      label = LANG_EN.installed.disable_integration;
      dangerAction = () => {
        this.disableIntegration();
      };
      children = this.disableIntegrationModalContent();
    }

    return {
      referId: 'RemoveIntegrationModal',
      isOpen: true,
      size: 'small',
      title: title,
      iconTitle: {
        icon: 'exclamation-circle',
        family: 'regular',
      },
      secondaryAction: {
        label: 'Cancel',
        onClick: () => {
          this.setState({
            modalProps: null,
            integrationToHandle: null,
          });
        },
      },
      dangerAction: {
        label: label,
        onClick: dangerAction,
      },
      children: children,
    };
  }

  showHideProps(more) {
    return {
      referId: 'ShowHideModal',
      isOpen: true,
      size: 'medium',
      title: 'Show ' + more.name,
      iconTitle: {
        icon: 'eye',
        family: 'regular',
      },
      secondaryAction: {
        label: 'Close',
        onClick: () => {
          this.setState({
            modalProps: null,
          });
        },
      },
      children: this.showHideModalContent(more),
    };
  }

  openModal(props, id, data) {
    this.setState({
      modalProps: props,
      integrationToHandle: id === 'remove' || id === 'disable' || id === 'enable' ? data : null,
    });
  }

  removeIntegrationFromList(integration_id) {
    this.props._handleRemovedIntegration(integration_id);
  }

  disableIntegrationFromList(integration_id) {
    this.props._handleDisabledIntegration(integration_id);
  }

  enableIntegrationFromList(integration_id) {
    this.props._handleEnabledIntegration(integration_id);
  }

  removeIntegration() {
    return deleteIntegration(this.state.integrationToHandle)
      .then(_ => {
        this.removeIntegrationFromList(this.state.integrationToHandle);
        this.setState({ modalProps: null });
      })
      .catch(error => console.log(error));
  }

  disableIntegration() {
    return disableIntegrationApi(this.state.integrationToHandle)
      .then(_ => {
        this.disableIntegrationFromList(this.state.integrationToHandle);
        this.setState({ modalProps: null });
      })
      .catch(error => console.log(error));
  }

  enableIntegration() {
    enableIntegrationApi(this.state.integrationToHandle)
    .then(_ => {
      this.enableIntegrationFromList(this.state.integrationToHandle);
      this.setState({ modalProps: null });
    })
    .catch(error => console.log(error));
  }

  // Prod env have different domains
  // https://apps.fireeye.com/helix/id/hexzsq689/
  // http://helix.eu.fireeye.com/helix/id/hexmkp624
  // http://helix.ap.fireeye.com/helix/id/hexspc764
  openSearch(instanceId, query) {
    const user = this.props.user;
    let domain = 'https://apps.fireeye.com';

    if (user.region === 'ap' || user.region === 'eu') {
      domain = `https://helix.${user.region}.fireeye.com`;
    }

    window.open(`${domain}/helix/id/${instanceId}/events/search?query=${query}`, '_blank');
  }

  integrationDetailsActionButtons(item = {}) {
    return (
      <>
        <muse-button
          size="md"
          button-type="hollow"
          onClick={e => {
            const query = encodeURIComponent(`class=fireeye_cloud_connect`);
            const helixId = item.values.helix_id;
            this.openSearch(helixId, `${query}&timeRange=24`);
          }}
        >
          <muse-icon name="search"></muse-icon>
          Audit Events
        </muse-button>
        {/* Azure AD doesn't send logs to Helix */}
        {item.original.type !== "ms_azure_ad" && (
          <muse-button
            size="md"
            button-type="hollow"
            onClick={e => {
              const sensorId = item.values.sender_id;
              const helixId = item.values.helix_id;
              const query = encodeURIComponent(`meta_cbid=${sensorId}`);
              this.openSearch(helixId, `${query}&timeRange=24`);
            }}
          >
            <muse-icon name="search"></muse-icon>
            View Logs
          </muse-button>
        )}
        {!item.original.enabled && (
          <muse-button
            size="md"
            button-type="hollow"
            onClick={e => {
              this.openModal(this.generateModalProps('enable'), 'enable', item.original.integration_id);
            }}
          >
            <muse-icon name="play"></muse-icon>
            {LANG_EN.installed.enable_integration}
          </muse-button>
        )}
        {item.original.enabled && (
          <muse-button
            size="md"
            button-type="hollow"
            onClick={e => {
              this.openModal(this.generateModalProps('disable'), 'disable', item.original.integration_id);
            }}
          >
            <muse-icon name="pause"></muse-icon>
            {LANG_EN.installed.disable_integration}
          </muse-button>
        )}
        <muse-button
          size="md"
          button-type="danger-hollow"
          onClick={e => {
            this.openModal(this.generateModalProps('remove'), 'remove', item.original.integration_id);
          }}
        >
          <muse-icon name="trash"></muse-icon>
          {LANG_EN.installed.remove_integration}
        </muse-button>
      </>
    );
  }

  integrationStatus(item) {
    let status = 0;

    if (item.customer_warning) {
      return 5;
    }

    if (!item.enabled) {
      return status;
    }

    //Config store integrations are always green
    const isConfigStore = item.type === 'etp_stats';
    if (isConfigStore) {
      return 1;
    }

    if (!item.last_read_unix && !item.last_event_unix) {
      status = 4;
    }

    if (item.last_read_unix && !item.last_event_unix) {
      status = 3;
    }

    if (item.last_read_unix && item.last_event_unix) {
      const now = dayjs();
      const diff = now.diff(dayjs.unix(item.last_event_unix), 'hours');
      if (diff > 24) {
        status = 2;
      } else {
        status = 1;
      }
    }

    return status;
  }

  getStatusClass(status) {
    let statusClassName;
    switch (status) {
      case 0:
        statusClassName = 'disabled';
        break;
      case 1:
        statusClassName = 'ok';
        break;
      case 2:
        statusClassName = 'review';
        break;
      case 3:
        statusClassName = 'warning';
        break;
      case 4:
        statusClassName = 'critical';
        break;
      case 5:
        statusClassName = 'verify';
        break;
      default:
        break;
    }
    return statusClassName;
  }

  render() {
    this.props.data.forEach(item => {
      item.status = this.integrationStatus(item);
      item.statusClass = this.getStatusClass(item.status);
    });
    this.props.data.sort((a, b) => a.status - b.status);

    return (
      <div className="EnabledIntegrations">
        <BasicTable
          data={this.props.data}
          integrationDetailsActionButtons={this.integrationDetailsActionButtons.bind(this)}
        ></BasicTable>

        <FeModal {...this.state.modalProps} onHide={() => this.setState({ modalProps: null })} />
      </div>
    );
  }
}

export default EnabledIntegration;
