import React, { useState, useEffect } from 'react';
import { API_CONFIG } from '../utils/config';
import { Link } from 'react-router-dom';
import Logo from '../components/logo';
import { getIntegrations } from '../utils/services';
const styles = {
  wrapper: {
    backgroundColor: '#fff',
    height: '100%',
    color: '#000',
    margin: 50,
  },
  page: {
    flexDirection: 'column',
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 35,
    margin: 40,
  },
  link: {
    color: '#000',
  },
  listItem: {
    listStyle: 'none',
  },
  title: {
    fontSize: 24,
  },
  vendor: {
    fontSize: 12,
    marginBottom: 40,
  },
  logo: {
    textAlign: 'center',
    fill: '#000',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitle: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    color: '#000',
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
};
export default function DocumentationIndex() {
  const [list, setList] = useState([]);
  const url = API_CONFIG.api.integrations;
  useEffect(() => {
    getIntegrations()
      .then(response => {
        setList(response.data);
      })
      .catch(error => console.error(error));
  }, [url]);
  return (
    <div style={styles.wrapper}>
      <div style={styles.page}>
        <div style={styles.header}>
          <div style={styles.logo}>
            <Logo />
          </div>
          <div style={styles.headerTitle}>Helix Connect</div>
        </div>
        <div>
          <h2 style={styles.title}>Documentation</h2>
          <h3 style={styles.vendor}>List of supported integrations</h3>
        </div>
        {list &&
          list.map(v => (
            <li style={styles.listItem} key={v.id}>
              <Link to={'/docs/' + v.integration} style={styles.link}>
              {v.company} - {v.name}
              </Link>
            </li>
          ))}
      </div>
    </div>
  );
}
