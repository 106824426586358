export const LANG_EN = {
  loading: {
    redirecting: 'Redirecting to FireEye IAM',
  },
  home: {
    app_title: 'Cloud Connect',
    app_description: 'Add and manage cloud connections for Helix.',
    installed_heading: 'Installed Integrations',
    available_heading: 'Available Integrations',
    filter_installed: 'Select a Helix instance',
    show_all: 'Show All',
    legend: {
      critical: 'Plugin not running',
      warning: 'Plugin not getting events',
      validate: 'Last event seen more than 24 hours ago',
    },
  },
  install: {
    regex_match: 'matches the expected format.',
    input_required: 'Input Required',
    verifying_integration: 'Verifying Integration',
    successful_integration: 'Successful Integration',
    success_message:
      'Your Helix instance is now ingesting data from this integration, you may close this window or return',
    success_message_link: 'home',
    failed_integration: 'Failed Connection',
    failure_message: 'Connection setup failed.',
    failure_message_link: 'home',
    step: 'Step',
    no_instance_selected: 'No Instance Selected',
    previous_step: 'Previous Step',
    next_step: 'Next Step',
    back_to_home: 'Back to Home',
    submit_and_verify: 'Submit and Verify',
    exit_preview: 'Exit Preview Mode',
    select_instance: 'Select a Helix Instance',
    select_one_instance: 'Please select one Helix Instance',
    helix_instance: 'Helix Instance',
    confirm_selection: 'Confirm Selection',
    cancel_selection: 'Cancel Selection',
    no_steps_found: 'Unable to load steps',
  },
  installed: {
    integration_details: 'Integration Details',
    integration_name: 'Name',
    integration_vendor: 'Vendor',
    integration_type: 'Type',
    integration_identifier: 'Identifier',
    integration_helix_id: 'Helix ID',
    integration_sender_id: 'Sensor ID',
    integration_date_installed: 'Created',
    remove_integration: 'Remove Connection',
    remove_integration_msg: [
      'Are you sure you want to remove this integration? This integrations data will no longer be ingested by Helix.',
      'You can always add this integration in the future via the Available Integrations section below.',
    ],
    disable_integration: 'Disable Connection',
    disable_integration_msg: [
      'Are you sure you want to disable this integration? This integrations data will no longer be ingested by Helix.',
      'You can always re-enable this integration in the future via the Available Integrations section below.',
    ],
    enable_integration: 'Enable Connection',
    enable_integration_msg: [
      'Are you sure you want to enable this integration? This integration data will start being ingested by Helix.',
      'You can always disable this integration in the future.',
    ],

    disabled_message: 'Disabled Message',
    last_event_time: 'Last Event',
    last_read: 'Last Poll',
    status: 'Status',
    average_eps: 'Average EPS'
  },
  errors: {
    unable_to_load_installed_integrations: 'No connections installed.',
    unable_to_load_available_integrations: 'No connections available.',
  },
};
