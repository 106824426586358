const environment = process.env.REACT_APP_ENV;
const environmentList = ['STAGE', 'QA', 'PROD', 'PROD_EU', 'PROD_AP'];
const isEnvironmentValid = environmentList.filter(x => x === environment);

if (!environment || !isEnvironmentValid.length > 0) {
  throw new Error('Environment not defined');
}

const IAM_CONFIG = {
  response_type: 'code',
  scope: 'openid profile api',
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_name: process.env.REACT_APP_CLIENT_NAME,
  authorization_endpoint: process.env.REACT_APP_AUTH_URL,
  redirect_uri: process.env.REACT_APP_AUTH_CALLBACK,
};

let APP_CONFIG = {
  name: 'Cloud Integrations',
  gainsight: process.env.REACT_APP_METRICS,
};

let API_CONFIG = {
  api: {
    base: '/api/',
    integrations: '/api/integrations/',
    disable_integrations: '/api/integrations/disable',
    enable_integrations: '/api/integrations/enable',
    edit_integration: '/api/integrations/edit',
    config: '/api/integrations/config/',
    details: '/api/integrations/details/',
    steps: '/api/steps/',
    userInfo: '/userinfo/',
  },
};

export { APP_CONFIG, API_CONFIG, IAM_CONFIG };
