import React, { useMemo, useEffect } from 'react';
import { LANG_EN } from '../utils/lang';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useRowState } from 'react-table';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from './pagination';
import ToggleableLabel from './ToggleableLabel';
import * as dayjs from 'dayjs';
import '../styles/components/basicTable.scss';
import { loadTableRows, saveTableRows } from '../utils/services';

function BasicTable(props) {
  let history = useHistory();

  const defaultPageSize = 50;
  const columns = useMemo(
    () => [
      {
        Header: LANG_EN.installed.integration_name,
        accessor: 'nickname',
        sortType: 'basic',
        Filter: SelectColumnFilter,
      },
      {
        Header: LANG_EN.installed.integration_vendor,
        accessor: 'vendor',
        sortType: 'basic',
        Filter: SelectColumnFilter,
      },
      {
        Header: LANG_EN.installed.integration_identifier,
        accessor: 'identifier',
        sortType: 'basic',
      },
      {
        Header: LANG_EN.installed.integration_sender_id,
        accessor: 'sender_id',
        sortType: 'basic',
        width: 300,
      },
      {
        Header: LANG_EN.installed.integration_helix_id,
        accessor: 'helix_id',
        sortType: 'basic',
        width: 300,
      },
      {
        Header: LANG_EN.installed.status,
        accessor: 'statusClass',
        Filter: SelectColumnFilter,
        sortType: 'basic',
        Cell: statusCell,
      },
      {
        Header: LANG_EN.installed.integration_date_installed,
        helpMessage: 'Date the integration was created',
        accessor: 'created_unix',
        sortType: 'basic',
        Filter: false,
        Cell: relativeDateCell,
      },
      {
        Header: LANG_EN.installed.last_event_time,
        helpMessage: 'Last time the integration received an event',
        accessor: 'last_event_unix',
        sortType: 'basic',
        Filter: false,
        Cell: relativeDateCell,
      },
      {
        Header: LANG_EN.installed.last_read,
        helpMessage: 'Last time the integration looked for events',
        accessor: 'last_read_unix',
        sortType: 'basic',
        Filter: false,
        Cell: relativeDateCell,
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const setParams = ({ filters = '' }) => {
    const searchParams = new URLSearchParams();
    searchParams.set('filters', JSON.stringify(filters));
    return searchParams.toString();
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let data = props.data;

  const tableInstance = useTable(
    {
      stateReducer: (newState, action, prevState) => {
        switch (action.type) {
          case 'init':
            let queryFilters = JSON.parse(query.get('filters')) || [];
            if (queryFilters.length > 0) {
              newState.filters = queryFilters;
            }
            break;
          default:
            break;
        }
      },
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetFilters: false,
      initialState: { pageSize: defaultPageSize },
      initialRowStateAccessor: () => ({ expanded: false, more: [], isLoading: false }),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowState
  );

  const {
    state: { filters },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageSize, pageIndex },
  } = tableInstance;

  useEffect(() => {
    const urlParams = setParams({ filters });
    if (urlParams) {
      history.push(`?${urlParams}`);
    }
  }, [filters, history]);

  const loadRowDetails = row => {
    if (!row.state.more.length > 0) {
      row.setState(old => ({
        ...old,
        isLoading: true,
      }));
      const integration_id = row.original.integration_id;
      const helixId = row.original.helix_id;

      loadTableRows({
        integration_id: integration_id,
        helix_id: helixId,
      })
        .then(res => {
          if (res.data.length > 0) {
            row.setState(old => ({
              ...old,
              more: res.data,
            }));
          }
        })
        .finally(_ => {
          row.setState(old => ({
            ...old,
            isLoading: false,
          }));
        })
        .catch(error => console.log(error));
    }
  };

  const editRowDetails = (row, fieldName, fieldValue) => {
    const integrationId = row.original.integration_id;
    return saveTableRows({
      integration_id: integrationId,
      fieldName,
      fieldValue,
    })
      .then(() => {
        let subitem =  row.state.more.find(x => x.name === fieldName);
        subitem.value = fieldValue;
        row.setState(old => ({
          ...old,
          more: row.state.more,
        }));
      })
  };

  const getCellProps = cellInfo => {
    if (cellInfo.column.id === 'statusClass') {
      return {
        className: cellInfo.value,
      };
    }

    if (['created_unix', 'last_event_unix', 'last_read_unix'].includes(cellInfo.column.id)) {
      return {
        style: {
          fontSize: '10px',
        },
      };
    }

    return {
      style: {},
    };
  };

  return (
    <div className="tableContainer">
      <div className="tableWrap">
        <muse-data-table id="table-one" custom-class="muse-data-table" compact="true">
          <div>
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th />
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        <div>
                          <span {...column.getSortByToggleProps()}>
                            {column.render('Header')}
                            {column.helpMessage ? (
                              <muse-tooltip>
                                <div slot="tooltip-trigger">
                                  <muse-icon name="help-circle" size="sm"></muse-icon>
                                </div>
                                <div>{column.helpMessage}</div>
                              </muse-tooltip>
                            ) : (
                              ''
                            )}
                            <div className="sort-box">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <muse-icon name="arrow-down" size="sm"></muse-icon>
                                ) : (
                                  <muse-icon name="arrow-up" size="sm"></muse-icon>
                                )
                              ) : (
                                ''
                              )}
                            </div>
                          </span>
                        </div>
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  const clickCallback = () => {
                    row.setState(old => ({
                      ...old,
                      expanded: !row.state.expanded,
                    }));
                    loadRowDetails(row);
                  };

                  return (
                    <React.Fragment key={'row-data-' + row.id}>
                      <tr
                        {...row.getRowProps()}
                        onClick={clickCallback}
                        className={`${row.state.expanded ? 'expanded' : 'collapsed'} ${
                          !row.original.enabled ? 'disabled' : ''
                        }`}
                        disabled={!row.original.enabled}
                      >
                        <td className="icon">
                          <i className="far fa-fw fa-chevron-circle-right" />
                        </td>
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps([
                                {
                                  ...cell.getCellProps([
                                    {
                                      className: cell.column.className,
                                      style: cell.column.style,
                                    },
                                    getCellProps(cell),
                                  ]),
                                },
                              ])}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.state.expanded && (
                        <tr className="integration-details" key={'row-expanded-' + row.id}>
                          <td colSpan="10">
                            <div className="expanded-content">
                              <div className="content-pane">
                                <div className="row">
                                  <>
                                    <div className="col-6">
                                      <h4 className="heading">{LANG_EN.installed.integration_details}</h4>
                                    </div>
                                    <div className="col-6 action-buttons">
                                      {props.integrationDetailsActionButtons(row)}
                                    </div>
                                    <div className="row col-12">
                                      {row.state.more &&
                                        row.state.more.length > 0 &&
                                        row.state.more.map((userInput, idx) => (
                                          <div className="col-md-6" key={idx}>
                                            <div className="integration-key">{userInput.displayName || userInput.name}</div>
                                            <div className="integration-value">
                                              <div className="flex-column">
                                                <ToggleableLabel
                                                  field={userInput}
                                                  id={idx}
                                                  row={row}
                                                  editHandler={editRowDetails}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      {row.state.isLoading && (
                                        <div className="details-loader">
                                          <muse-spinner custom-className="component-spinner" size="lg">
                                            Loading
                                          </muse-spinner>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                </div>
                                <div className="row">
                                  <div className="disable-details col-12">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <div className="integration-key">{LANG_EN.installed.last_event_time}</div>
                                        <div className="integration-value">{row.original.last_event_time}</div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="integration-key">{LANG_EN.installed.last_read}</div>
                                        <div className="integration-value">{row.original.last_read}</div>
                                      </div>
                                      <div className="col-md-6">
                                        {row.original.disabled_message && (
                                          <div>
                                            <div className="integration-key">{LANG_EN.installed.disabled_message}</div>
                                            <div className="integration-value">{row.original.disabled_message}</div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </muse-data-table>
        <Pagination
          totalRecords={tableInstance.rows.length}
          pageSize={pageSize}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
        ></Pagination>
      </div>
    </div>
  );
}

function statusCell({ cell }) {
  if (cell.row.original.customer_warning) {
    return (
      <muse-tooltip close-icon>
        <div slot="tooltip-trigger">
          <muse-icon name="alert-triangle" size="md"></muse-icon>
        </div>
        {cell.row.original.customer_warning}
      </muse-tooltip>
    );
  } else {
    return <div className="swatch" />;
  }
}

const relativeDateCell = ({ cell }) => {
  const isPollingCell = cell.column.id === "last_read_unix";
  const isIntegrationInline = cell.row.original.integration_type !== 'polling';
  const isConfigStore = cell.row.original.type === 'etp_stats';
  const isEnabled = cell.row.original.enabled;
  const isLastTimestamp = cell.column.id !== 'created_unix';

  //Inline integrations that are not disabled don't poll
  if (isIntegrationInline && isPollingCell & isEnabled) {
    return 'Listening...'
  }

  //Config store integrations dont have last read/last event
  if (isEnabled && isConfigStore && isLastTimestamp) {
    return 'NA';
  }

  const date = cell.value;
  const value = date ? dayjs.unix(date).fromNow() : 'Never';
  return value;

};

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return Array.from(options.values()).sort();
  }, [id, preFilteredRows]);

  return (
    <muse-select>
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </muse-select>
  );
}

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
      className="muse-filter muse-input"
    />
  );
}

export default BasicTable;
