import React, { useState } from 'react';
import { FeButton, FeInputField, FeTextarea } from 'fe-fabric-react';

export default function ToggleableInput(props) {
  const item = props.field;
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState(item.value);
  const [oldValue] = useState(item.value);

  const shouldRenderTextArea = val => {
    return Array.isArray(val) || val.indexOf('\n') > 0;
  };

  const reset = () => {
    setEditing(false);
    setValue(oldValue);
  };

  const save = e => {
    if (value === '') {
      return;
    }
    setValue(value);
    setEditing(false);

    props.editHandler(props.row, props.field.name, value).catch(e => {
      setValue(oldValue);
    });
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      save(e);
    } else if (e.keyCode === 27) {
      reset();
    }
  };

  const handleTextAreaChange = e => {
    setValue(e.target.value);
  };

  return (
    <>
      {isEditing ? (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          {
            
            shouldRenderTextArea(value) ? (
              <FeTextarea
                rows="3"
                disabled={false}
                id={props.id.toString()}
                value={Array.isArray(value) ? value.join('\n') : value}
                onChange={handleTextAreaChange}
              />
            ) : (
              <FeInputField
                id={props.id.toString()}
                label=""
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                style={{ width: '250px' }}
              />
            )
          }
          <FeButton feStyle="primary" size="md" icon="save" style={{ height: '34px' }} onClick={save} />
          <FeButton feStyle="primary-danger" size="md" icon="ban" onClick={reset} style={{ height: '34px' }} />
        </div>
      ) : (
        <>
          <div
            className="value"
            onClick={e => {
              setEditing(!isEditing);
            }}
          >
            {Array.isArray(value) ? value.join(' | ') : value}
          </div>
        </>
      )}
    </>
  );
}
