import * as Sentry from '@sentry/browser';

const aptrinsic = window.aptrinsic;

export function trackPxInstall(success = false, integration, helixid) {
  if (aptrinsic) {
    aptrinsic('track', 'Install', {
      integration,
      helixid,
      success,
    });
  }
}

export function trackSentryError(error, errorType) {
  Sentry.withScope(function (scope) {
    if (errorType) {
      scope.setExtra('error type', errorType);
    }

    Sentry.captureException(error);
  });
}

export function setupGainsight(userInfo, gainsightId) {
  let user = {
    id: userInfo.username,
    email: userInfo.username,
  };
  let company = '';
  for (let k in userInfo.helix_ids) {
    if (userInfo.helix_ids[k] === userInfo.helix_id) {
      company = k;
      break;
    }
  }
  let customer = {
    id: userInfo.helix_id,
    name: company,
  };
  let code = 'aptrinsic("identify", ' + JSON.stringify(user) + ', ' + JSON.stringify(customer) + ');';

  return (
    `(function(n,t,a,e){var i="aptrinsic";n[i]=n[i]||function(){
            (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;
          var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
          var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c);
          ` +
    code +
    `
      })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","${gainsightId}");`
  );
}
