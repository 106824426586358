import ToggleableInput from "./toggleable/toggleableInput";
import React from 'react';
import ToggleableToggle from "./toggleable/toggleableToggle";

export default function ToggleableLabel(props){
  const {id, field, editHandler, row} = props;
  switch(field.type){
    case 'toggle':
      return(<ToggleableToggle row={row} id={id} editHandler={editHandler} field={field} />);
    case 'string':
    default:
      return (<ToggleableInput row={row} id={id} editHandler={editHandler} field={field} />);
  }
}