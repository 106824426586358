import React from 'react';
import EnabledIntegrations from '../components/enabledIntegrations';
import { Link } from 'react-router-dom';
import { LANG_EN } from '../utils/lang';
import * as dayjs from 'dayjs';
import '../styles/containers/Home.scss';
import Legend from '../components/legend';
import { getIntegrationsConfig } from '../utils/services';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      installedIntegrations: [],
      installedIntLoading: false,
      isEmpty: false,
    };
  }

  componentDidMount() {
    const showLoader = true;
    this.getInstalledIntegrations(showLoader);
  }

  getInstalledIntegrations(showLoader = false) {
    this.setState({ installedIntLoading: showLoader });
    getIntegrationsConfig()
      .then(response => {
        this.setInstalledIntegrations(response.data);
      })
      .catch(error => console.error(error))
      .finally(_ => this.setState({ installedIntLoading: false }));
  }

  setInstalledIntegrations(installedIntegrations = []) {
    this.setState({
      installedIntegrations: installedIntegrations,
      isEmpty: installedIntegrations.length === 0,
    });
  }

  removeInstalledIntegration(integration_id) {
    const filteredInstalledInt = this.state.installedIntegrations.filter(e => e.integration_id !== integration_id);
    this.setInstalledIntegrations(filteredInstalledInt);
  }

  disableInstalledIntegration(integration_id) {
    const installedIntegrations = this.state.installedIntegrations.map(e => {
      if (e.integration_id === integration_id) {
        e.enabled = false;
      }
      return e;
    });
    this.setInstalledIntegrations(installedIntegrations);
  }

  enableInstalledIntegration(integration_id) {
    const installedIntegrations = this.state.installedIntegrations.map(e => {
      if (e.integration_id === integration_id) {
        e.enabled = true;
      }
      return e;
    });
    this.setInstalledIntegrations(installedIntegrations);
  }

  emptyMessage() {
    return (
      <div className="empty-message">
        <img src="/images/cloudconnect-emptystate.svg" alt="No connections yet" className="empty-image" />
        <p className="stats">Looks like you don't have any connections yet</p>
        <p className="body-type--primary">Add cloud connections to secure your organization</p>
        <Link to="/available">
          <muse-button>See Available Connections</muse-button>
        </Link>
      </div>
    );
  }

  toolBar() {
    return (
      <div className="action-buttons">
        <Legend></Legend>
        <div className="alt-table-text"> Last Update: {dayjs().format()}</div>
        <muse-tooltip>
          <div slot="tooltip-trigger">
            <muse-icon name="rotate-ccw" size="md" onClick={event => this.getInstalledIntegrations(true)}></muse-icon>
          </div>
          <div>Refresh</div>
        </muse-tooltip>
        <Link to="/available">
          <muse-button>
            <muse-icon name="plus-circle"></muse-icon>
            Add Cloud Connection
          </muse-button>
        </Link>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        <div className="fe-hero bg-dark">
          {this.state.installedIntLoading && (
            <div className="loaderDiv">
              <muse-spinner custom-className="component-spinner" size="lg">
                Loading
              </muse-spinner>
            </div>
          )}
          <div className="fe-hero__title">
            <h1 className="page-title fe-hero__title-text">{LANG_EN.home.app_title}</h1>
          </div>
          <div className="fe-hero__body">
            <div className="row">
              <div className="col-3">
                <p>{LANG_EN.home.app_description}</p>
              </div>
              <div className="col-9 action-buttons-container">{this.state.installedIntegrations.length > 0 && this.toolBar()}</div>
            </div>

            <div className="row">
              <div className="col-12">{this.state.isEmpty && this.emptyMessage()}</div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {this.state.installedIntegrations.length > 0 && (
            <EnabledIntegrations
              data={this.state.installedIntegrations}
              user={this.props.user}
              _handleRemovedIntegration={this.removeInstalledIntegration.bind(this)}
              _handleDisabledIntegration={this.disableInstalledIntegration.bind(this)}
              _handleEnabledIntegration={this.enableInstalledIntegration.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default HomePage;
