import React from 'react';
import { FeDropdown } from 'fe-fabric-react';
import '../styles/components/AvailableIntegration.scss';

export default function AvailableIntegration({ item }) {
  let onClick = () => {};

  let basicList = [
    {
      label: 'Install',
      route: `/install/${item.integration}`,
    },
    {
      label: 'View Install Steps',
      route: `/install/${item.integration}/?preview=true`,
    },
    {
      label: 'Print Installation Steps',
      route: `/docs/${item.integration}`,
    },
  ];
  return (
    <div className="col-3">
      <div className="AvailableIntegration">
        <div className="item">
          <img className="integration-image" src={item.logo} alt={item.name} height="50" width="50" />
        </div>
        <div className="item">
          <p className="integration-name">{item.name}</p>
          <p className="company-name">{item.company}</p>
        </div>
        <div className="item">
          <FeDropdown
            label=" "
            onItemClick={onClick}
            buttonStyle="primary"
            position="bottom-right"
            size="sm"
            list={basicList}
          />
        </div>
      </div>
    </div>
  );
}
