import React from 'react';
import { Route } from 'react-router-dom';
import { AuthConsumer } from 'react-check-auth';
import { IAM_CONFIG } from '../utils/config';
import { LANG_EN } from '../utils/lang';
import * as Sentry from '@sentry/browser';

const formatIAMRedirect = () => {
  return (
    IAM_CONFIG.authorization_endpoint +
    'client_id=' +
    IAM_CONFIG.client_id +
    '&' +
    'redirect_uri=' +
    IAM_CONFIG.redirect_uri +
    '&' +
    'response_type=' +
    IAM_CONFIG.response_type +
    '&' +
    'scope=' +
    IAM_CONFIG.scope
  );
};

export const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = Component => props => (
    <AuthConsumer>
      {({ userInfo, isLoading, error }) => {
        if (userInfo) {
          // redirect to the respective IAM
          if( userInfo.redirect_url && userInfo.redirect_url !== '' ){
            window.location = userInfo.redirect_url;
          }
          const helix_id = userInfo && userInfo.helix_id ? userInfo.helix_id : '';
          Sentry.configureScope(function(scope) {
            scope.setTag("helix_id", helix_id);
          });

          return <Component {...props} user={userInfo} />;
        } else {
          if (isLoading === false && userInfo === null) {
            window.location = formatIAMRedirect();
          }
          return (
            <div className="redirection">
              <muse-spinner custom-class="component-spinner" size="lg">
                {LANG_EN.loading.redirecting}
              </muse-spinner>
            </div>
          );
        }
      }}
    </AuthConsumer>
  );

  return <Route {...rest} render={renderFn(component)} />;
};
